import React from 'react'
import Gov from '../components/goverence/Index'

function Goverence() {
  return (
    <>
    <Gov/>
    </>
  )
}

export default Goverence