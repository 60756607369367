/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { Link as Rlink, animateScroll as scroll } from "react-scroll";

export default function Navbar() {
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 600) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let classNmae = ["swap-nav"];
  if (scrolled) {
    classNmae.push("fixed-top");
  }
  return (
    <header className='swap-nav'>
      <nav className='container navbar navbar-expand-lg navbar-light'>
        <Link to='/'>
          <a className='navbar-brand'>
            <img
              src='logo.png'
              alt='Logo'
              width='180px'
              // className='img-fluid'
            />
          </a>
        </Link>
        <div id='main_navbar_root' className='collapse navbar-collapse'>
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item round-btn'>
              <a
                href='https://secretpep.com'
                target='_BLANK'
                className='nav-link'>
                New Proposal
              </a>
            </li>
          </ul>
          {/* <div className="navbar-span">
          <ul className='navbar-nav m-auto'>
            <li className='nav-item round-btn'>
              <a
                href='https://secretpep.com/vote/'
                target='_BLANK'
                className='nav-link'>
                New Proposal
              </a>
            </li>
          </ul>
          </div> */}
        </div>
      </nav>
    </header>
  );
}
