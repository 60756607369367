import React from "react";
import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

export default function Gov() {
    const [totalProposals, setTotalProposals] = useState(0);
    const [proposals, setProposals] = useState();
    const baseUrl = "https://secretpep.com/vote/core/#/secretpep.eth/proposal/";
    const now = moment();

    useEffect(() => {
        axios({
          method: "post",
          url: "https://hub.snapshot.org/graphql",
          data: {
            query: `
                    query Proposals {
                        proposals(
                          first: 20,
                          skip: 0,
                          where: {
                            space_in: ["secretpep.eth"],
                           
                          },
                          orderBy: "created",
                          orderDirection: desc
                        ) {
                          id
                          title
                          body
                          choices
                          start
                          end
                          snapshot
                          state
                          author
                          space {
                            id
                            name
                          }
                        }
                      }`,
          },
        }).then(
          res => {
                      console.log(res.data.data.proposals)
            setProposals(res.data.data.proposals);
    
            setTotalProposals(res.data.data.proposals.length);
          },
          error => {},
        );
      }, []);
    
    function ProposalsLink(url) {
        window.open(url, '_blank').focus();
    }
    return (
        <React.Fragment>
            <div className="container-fluid gov mt-5">
                <div className="container mt-5 pt-5">
                    <div className="row">
                        <div className="col-xs-12 pl-2 mt-5">
                            <h1 className="text-dark font-weight mt-4 heading">Governance</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="tile m-2 mr-4">
                                <h3 className="font-weight-bold">0</h3>
                                <h4>Voting Addresses</h4>
                            </div>
                        </div>
                        <div className="col-xs-4">
                            <div className="tile m-2 mr-4">
                                <h3 className="font-weight-bold">0</h3>
                                <h4>Voting Delegated</h4>
                            </div>
                        </div>
                        <div className="col-xs-4">
                            <div className="tile m-2 mr-4 pr-5">
                                <h3 className="font-weight-bold pr-5">{totalProposals}</h3>
                                <h4 className="pr-5">Proposals &nbsp;</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container proposals">
                <div class="card shadow">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item font-weight-bolder fs-24 p-3 pl-4">Recent Proposals</li>
                        {proposals ? (
              <React.Fragment>
                {proposals.map((key,index)=> {
                  return (
                      <React.Fragment>
                        {/* {console.log(key.title)} */}
                      <li className='list-group-item p-3 pb-4 pl-5 proposalsLink' key={index}>
                        <span
                          className='font-weight-bold fs-20 link'
                          onClick={() => {
                            ProposalsLink(baseUrl + key.id);
                          }}>
                          {key.title}
                        </span>
                        <span className='d-block mt-1'>
                          <span
                            className={`badge ${
                              now.diff(
                                moment.unix(key.end),
                                "hours",
                              ) > 0
                                ? "badge-success"
                                : "badge-warning"
                            }`}>
                            {now.diff(
                              moment.unix(key.end),
                              "hours",
                            ) > 0
                              ? "Closed"
                              : "In Progress"}
                          </span>
                          &nbsp;{" "}
                          <span className='text-muted'>
                            #{totalProposals - index}{" "}
                            <i className='fa fa-circle'></i> &nbsp;
                            {moment
                              .unix(key.start)
                              .format("MMMM Do, YYYY")}
                            &nbsp; - &nbsp;
                            {moment
                              .unix(key.end)
                              .format("MMMM Do, YYYY")}
                          </span>
                        </span>
                      </li>
                    </React.Fragment>
                  );
                })}
               {Object.keys(proposals).length === 0 &&
                                        <React.Fragment>
                                            <div className="p-5 m-auto text-center">
                                                <h5>Oops, we can't find any proposal.</h5>
                                            </div>
                                        </React.Fragment>
                                    }
              </React.Fragment>
            ) : (
                <>
                <div className="text-center mt-4 mb-4">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div><br />
                                    Please wait, <br /> only for you.
                                </div>
                            {/* <div class="lds-ring mt-5 mb-5"><div></div><div></div><div></div><div></div></div> */}
                </>
            )}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}