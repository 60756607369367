/** @format */

import "./App.scss";
import Goverence from "./pages/Goverence";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layouts/Base";
import ReactGA from "react-ga";
ReactGA.initialize("UUA-269597103-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Goverence />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </>
  );
}

export default App;
